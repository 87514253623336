<template>
    <b-nav class="mx-right user-menu" :class="{ loggedIn: currentUser.uid !== ''}" order="2" order-md="2" order-lg="3" order-xl="3" v-if="this.$route.name !== 'Clarinha'">
        <b-nav-text class="current-user" v-if="currentUser.name && this.$route.name !== 'Intro'">
            <div class="user-name">
                <b><span style="text-transform: capitalize">{{ currentUser.name }}</span> {{ currentUser.avatarData.title }}</b><br>
              กำลังทำภารกิจอยู่
            </div>
        </b-nav-text>
        <b-nav-text v-if="this.$route.name === 'Intro' || (currentUser.name === '')">
            <router-link class="register-header" to="/register">{{ $t('nav.register') }}</router-link>
            <router-link class="login-header" to="/login">{{ $t('nav.login') }}</router-link>
        </b-nav-text>
        <b-nav-item-dropdown right v-if="this.$route.name !== 'Intro' && (currentUser.name !== '' || users.length != 0)">
            <template #button-content>
                {{ $t('nav.switch_user') }}
            </template>
            <b-dropdown-item-button @click="goToUser(user)" v-for="(user, k) in users" :key="k">
                <div class="user-selection">
                    <div class="avatar" v-if="user.avatarData.body">
                        <div class="face" v-if="user.avatarData.bg"><img :src="user.avatarData.bg.image"/></div>
                        <div class="head" v-if="user.avatarData.body"><img :src="user.avatarData.body"/></div>
                        <div class="face" v-if="user.avatarData.horn"><img :src="user.avatarData.horn.image"/></div>
                        <div class="face" v-if="user.avatarData.nose"><img :src="user.avatarData.nose.image"/></div>
                        <div class="face" v-if="user.avatarData.mouth"><img :src="user.avatarData.mouth.image"/></div>
                    </div>
                    <div class="name">{{ user.name}}</div>
                </div>
            </b-dropdown-item-button>
            <b-dropdown-item to="/intro" class="login"><img src="../assets/img/add.png"/><span>เพิ่ม "นักสร้างการเปลี่ยนแปลง"</span></b-dropdown-item>
            <b-dropdown-item v-if="currentUser.name !== ''" @click="logOutUser()" class="logout"><span class="icon-out"><i class="fa fa-sign-out" aria-hidden="true"></i></span><span>ออกจากระบบ ผู้ใช้ปัจจุบัน</span></b-dropdown-item>
            <b-dropdown-item v-if="currentUser.name !== '' && users.length > 0" @click="logOutAllUsers()" class="logout"><span class="icon-out"><i class="fa fa-sign-out" aria-hidden="true"></i></span><span>Logout All Users</span></b-dropdown-item>
        </b-nav-item-dropdown>
    </b-nav>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'UserSwitch',
  computed: {
    ...mapGetters(['currentUser', 'users'])
  },
  methods: {
    ...mapActions(['switchUser', 'logOut', 'logOutAll', 'setGuide']),
    ...mapMutations(['setGuideActive', 'setAvatarElementUnlocked']),
    goToUser (user) {
      this.switchUser(user)
      this.$root.$emit('dashboard::switchUser')
      if (this.$route.name !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard' })
      }
    },
    logOutUser () {
      this.logOut()
      this.$router.push('/')
    },
    logOutAllUsers () {
      this.logOutAll()
      this.$router.push('/')
    },
    redirectAvatar () {
      if (this.$route.name !== 'Avatar') {
        this.$router.push('/avatar')
      }
    },
    startGuide () {
      this.setGuide()
      this.setGuideActive()
    },
    testUnlockMessage () {
      this.setAvatarElementUnlocked({ key: 'avatar0', state: true })
      this.setAvatarElementUnlocked({ key: 'avatar1', state: true })
      this.setAvatarElementUnlocked({ key: 'avatar2', state: true })
    }
  }
}
</script>

<style scoped>
    .user-menu .dropdown-item {
        text-align: center !important;
    }

    .user-menu .dropdown-item i {
        font-size: 25px;
    }

    .avatar .head, .avatar .face, .avatar .tool {
        position: absolute;
        right: 0;
    }
    .avatar .tool {
      bottom: -2px;
      right: -3px;
    }

    .avatar .head img, .avatar .face img {
        width: 60px;
    }
    .avatar .tool img {
      width: 30px
    }

    .avatar {
        position: relative;
    }

    .current-user .avatar {
        top: -25px;
        right: 5px;
    }

    .current-user .user-name {
        margin-top: 15px;
    }

    .user-selection {
        height: 60px;
    }

    .user-selection .name {
        position: relative;
        top: 35px;
        text-align: center;
    }

    .user-selection .avatar {
        right: 20px;
    }

    .login, .logout {
        text-align: center;
    }
</style>
