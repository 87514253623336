<template>
    <div class="home">
      <vue-title></vue-title>
      <b-container id="styledElement">
            <div class="elemnts-box" id="resizeElement">
                <span class="green-shape-right"></span>
                <span class="green-splodge"></span>
                <div class="white-box home-avatar-icons">
                <span class="mobile-green-bottom"></span>
                <span class="mobile-footer-bottom"></span>
                <span class="white-content-bg"></span>
                   <!-- <b-row>
                        <b-col class="text-center">
                            <h1>{{ $t('home.welcome_text_title') }}</h1>
                        </b-col>
                    </b-row> -->
                    <b-row>
                        <b-col class="text-center">
                            <h3 v-html="$t('home.welcome_text')"></h3>
                        </b-col>
                    </b-row>
                    <div class="icons-holder">
                    <b-row>
                        <!--<b-col class="home-teacher-button" order="2" order-md="1" order-lg="1" order-xl="1" ref="animateMe" :class="['text-center animate animate1 hiddenload', { animatestart: screenLoading && canAnimate }]">
                            <router-link :to="{ name: 'Info', params: { pageSlug: 'teacher' } }">
                                <div class="icon icon-teacher" size="6rem"></div>
                                <br/>
                                <a class="btn home-teacher-button teacher-btn btnAnimate animate__animated animate__pulse animate__infinite animate__slower">{{ $t('home.teacher') }}</a>
                            </router-link>
                        </b-col>-->
                        <b-col order="1" oorder-md="2" order-lg="1" order-xl="2" class="text-center animate animate2 home-kid-button">
                            <a @click="startRegister(true)">
                                <div class="icon icon-kid" size="6rem"></div>
                                <br/>
                                <a class="btn home-kid-button kid-btn animate__animated animate__pulse animate__infinite animate__slower">{{ $t('home.kid') }}</a>
                            </a>
                        </b-col>
                        <b-col order="3" class="text-center animate animate3 home-grownup-button">
                            <router-link :to="{ name: 'Info', params: { pageSlug: 'grownup' } }">
                                <div class="icon icon-grown" size="6rem"></div>
                                <br/>
                                <a class="btn home-grownup-button grown-btn animate__animated animate__pulse animate__infinite animate__slower">{{ $t('home.grown_up') }}</a>
                            </router-link>
                        </b-col>
                    </b-row>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      canAnimate: false
    }
  },
  computed: {
    ...mapGetters(['loading', 'currentUser']),
    screenLoading () {
      return !this.loading
    }
  },
  methods: {
    ...mapMutations(['setRegisterType']),
    onClassChange (classAttrValue) {
      const classList = classAttrValue.split(' ')
      /* if (classList.includes('animatestart') && !classList.includes('animateddone')) {
        animateCSS('.animate1', 'bounceInRight').then((message) => {
          animateCSS('.animate2', 'bounceInRight').then((message) => {
            animateCSS('.animate3', 'bounceInRight').then((message) => {
            })
          })
        })
      } */
    },
    startRegister (isKid) {
      this.setRegisterType(isKid)
      this.$router.push({ name: 'Intro' })
    }
  },
  mounted () {
    if (this.currentUser && this.currentUser.active) {
      this.$router.push({ name: 'Dashboard' })
    }
    this.canAnimate = true
    this.observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName)
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue)
        })
      }
    })

    /*
    this.observer.observe(this.$refs.animateMe, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class']
    })
    /* if (this.loading === false) {
        const elements = document.querySelectorAll('.animate')
        elements.forEach(function (element) {
          element.classList.add('animatestart')
        })
    } */
  }
}

function animateCSS (element, animation, prefix = 'animate__') {
  return new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`
    const node = document.querySelector(element)

    if (node) {
      node.classList.add(`${prefix}animated`, animationName)
      node.classList.add('animateddone')

      node.addEventListener('animationend', handleAnimationEnd, { once: true })
    }
    function handleAnimationEnd (event) {
      event.stopPropagation()
      /* node.classList.remove(`${prefix}animated`, animationName) */
      resolve('Animation ended')
    }
  })
}
</script>
