<template>
  <b-navbar-nav class="mx-auto progress-box" id="progress-box" :class="{ progressBoxFixed: isFixed,  isSafari: isSafari }" v-if="show && step">
    <b-nav-item>
      <div class="topic-progress-wrapper">
          <ul class="topic-progress">
              <li :class="{ active: step === 1 }">
                  {{ $t('topic_progress.watch') }}
                  <i class="icon"></i>
              </li>
              <li :class="{ active: step === 2 }">
                  {{ $t('topic_progress.read') }}
                  <i class="icon"></i>
              </li>
              <li :class="{ active: step === 3 }">
                  {{ $t('topic_progress.challenge') }}
                  <i class="icon"></i>
              </li>
              <li :class="{ active: step === 4 }">
                  {{ $t('topic_progress.quiz') }}
                  <i class="icon"></i>
              </li>
              <li :class="{ active: step === 5 }">
                  {{ $t('topic_progress.reward') }}
              </li>
            <li v-if="$route.params.mission === 'land'" :class="{ active: step === 6 }">
              เกม
            </li>
          </ul>
      </div>
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
let scrollEv = null

const isSafari = navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && !navigator.userAgent.match(/android/i)

export default {
  name: 'TopicProgress',
  data () {
    return {
      topicStep: 0,
      isFixed: true
    }
  },
  mounted () {
    this.$root.$on('topic::stepChanged', (step) => {
      this.topicStep = step.step
    })
    scrollEv = document.addEventListener('scroll', e => {
      this.isFixed = !((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 110)
    })
  },
  computed: {
    show () {
      return ['Topic', 'Challenge', 'Quiz', 'Reward', 'Word', 'Phrase', 'Game'].includes(this.$route.name)
    },
    step () {
      switch (this.$route.name) {
        case 'Topic':
          switch (this.topicStep) {
            case 0:
              return false
            case 1:
              return 1
            case 2:
              return 2
            case 3:
              return 5
            default:
              return false
          }
        case 'Challenge':
          return 3
        case 'Quiz':
          return 4
        case 'Word':
          return 4
        case 'Phrase':
          return 4
        case 'Reward':
          return 5
        case 'Game':
          return 6
        default:
          return false
      }
    },
    isSafari () {
      return isSafari
    }
  },
  watch: {
    $route (from, to) {
      if (from.name !== 'Topic') {
        this.topicStep = 0
      }
    }
  },
  beforeDestroy () {
    removeEventListener('scroll', scrollEv)
  }
}
</script>

<style scoped>
    .topic-progress {
        list-style-type: none;
        margin-left: auto;
        margin-right: auto;
    }

    .topic-progress li {
        float: left;
        min-width: 100px;
        text-align: center;
    }

    .topic-progress-wrapper {
        width: 100%;
    }

    .topic-progress li:after {
        content: '';
        position: absolute;
        font-size: 20px;
        width: 86px;
        border-bottom: 2px solid #000000;
        margin-top: 12px;
        color: #000;
    }

    .topic-progress li:last-child:after {
        border-bottom: none;
    }

    .topic-progress .active {
        color: #FC335D;
    }
</style>
