<template>
  <div>
    <b-modal id="leavingSiteModal" :hide-header="true" :hide-footer="true">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <img :src="image">
            <div class="lsm-content-wrapper">
              <h3>คุณกำลังจะออกจาก Dirt Is Good Academy เพื่อเยี่ยมชมเว็บไซต์อื่น ขอให้พ่อแม่หรือผู้ปกครองตรวจสอบก่อน และเราขอให้คุณปลอดภัยในโลกออนไลน์!</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 btn-wrapper">
          <button @click="$bvModal.hide('leavingSiteModal')" class="btn btn-primary stay-btn">อยู่ต่อ</button>
        </div>
        <div class="col-4 monster p-2">
          <div v-if="currentUser.avatarData.body">
            <div class="avatar" v-if="currentUser.avatarData.body">
              <div class="face" v-if="currentUser.avatarData.bg"><img :src="currentUser.avatarData.bg.image"/></div>
              <div class="head">
                <img :src="currentUser.avatarData.body"/>
              </div>
              <div class="face" v-if="currentUser.avatarData.horn"><img :src="currentUser.avatarData.horn.image"/></div>
              <div class="face" v-if="currentUser.avatarData.nose"><img :src="currentUser.avatarData.nose.image"/></div>
              <div class="face" v-if="currentUser.avatarData.mouth"><img :src="currentUser.avatarData.mouth.image"/></div>
            </div>
          </div>
          <img v-else :src="defaultImage">
        </div>

        <div class="col-4 btn-wrapper">
          <button @click="goToUrl()" target="_blank" class="btn btn-primary leave-btn">ไป</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import image from '../assets/img/logo.png'
import defaultImage from '../assets/img/monster.png'
export default {
  name: 'LeavingSite',
  data () {
    return {
      goUrl: null,
      image: image,
      defaultImage: defaultImage
    }
  },
  mounted () {
    document.body.addEventListener('click', e => {
      const target = e.target
      if (target.nodeName === 'A') {
        const regex = new RegExp(window.location.host, 'g')
        if (target.href.length && target.href.match(regex) == null) {
          e.preventDefault()
          this.goUrl = target.href
          this.$bvModal.show('leavingSiteModal')
        }
      }
    })
  },
  methods: {
    goToUrl () {
      window.open(this.goUrl, '_blank')
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style>
  #leavingSiteModal .modal-content {
    background: url('../assets/img/body-background.png') top center no-repeat;
    min-height: 300px;
    border-radius: 20px;
    border: 5px #FFFFFF solid;
    max-width: 608px;
  }

  .lsm-content-wrapper {
    background: url('../assets/img/bubble.png') top center no-repeat;
    padding: 30px 50px 60px;
    background-size: contain;
  }

  .lsm-content-wrapper h3 {
    font-size: 22px;
    color: #002A6F;
    font-family: Gotham-Bold;
    padding-bottom: 10px;
  }

  .lsm-content-wrapper p {
    font-size: 17px;
    color: #002A6F;
    font-family: Gotham-Book-Regular;
    padding: 5px;
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-wrapper .btn {
    border: none;
  }

  .btn-wrapper .stay-btn {
    background: url('../assets/img/stay.png') top center no-repeat;
    min-width: 150px;
    font-size: 26px;
    font-family: "Open Sans";
    font-weight: 900;
    background-size: contain;
  }

  .btn-wrapper .leave-btn {
    background: url('../assets/img/leave.png') top center no-repeat;
    min-width: 150px;
    font-size: 26px;
    font-weight: 900;
    font-family: 'Open Sans', sans-serif;
    color: #00AB4F;
    background-size: contain;
  }

  .monster {
    min-height: 150px;
  }

  .monster img {
    width: 100%;
  }

  .avatar .head, .avatar .face, .avatar .tool {
    position: absolute;
    right: 0;
  }

  @media (max-width: 479px) {
    .btn-wrapper .stay-btn, .btn-wrapper .leave-btn {
      font-size: 5vw;
    }

    .lsm-content-wrapper h3 {
      font-size: 3.5vw;
    }

    .lsm-content-wrapper p {
      font-size: 2.8vw;
    }

    .lsm-content-wrapper {
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 2%;
      width: 86%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .monster {
    min-height: 150px;
  }

  .avatar .head, .avatar .face, .avatar .tool {
    position: absolute;
    right: 0;
  }
</style>
