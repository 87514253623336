import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Intro from '../views/Intro'
import { adobeAnalytics } from '@/analytics'

const MissionTopics = () => import(/* webpackChunkName: "mission" */ '../views/MissionTopics.vue')
const Missions = () => import(/* webpackChunkName: "mission" */ '../views/Missions.vue')
const Topic = () => import(/* webpackChunkName: "mission" */ '../views/Topic')
const Avatar = () => import(/* webpackChunkName: "avatar" */ '../views/Avatar')
const AvatarGame = () => import(/* webpackChunkName: "avatar" */ '../views/AvatarGame')
const AvatarGameIntroScreen = () => import(/* webpackChunkName: "avatar" */ '../views/AvatarGameIntroScreen')
const AvatarGameIntroSplash = () => import(/* webpackChunkName: "avatar" */ '../views/AvatarGameIntroSplash')
const AvatarGameWelcome = () => import(/* webpackChunkName: "avatar" */ '../views/AvatarGameWelcome')
const VideoIntro = () => import(/* webpackChunkName: "avatar" */ '../views/VideoIntro')
const Challenge = () => import(/* webpackChunkName: "mission" */ '../views/Challenge')
const Quiz = () => import(/* webpackChunkName: "quiz" */ '../views/Quiz')
const Clarinha = () => import(/* webpackChunkName: "clarinha" */ '../views/Clarinha')
const Word = () => import(/* webpackChunkName: "word" */ '../views/Word')
const Reward = () => import(/* webpackChunkName: "mission" */ '../views/Reward')
const Phrase = () => import(/* webpackChunkName: "phrase" */ '../views/Phrase')
const Info = () => import(/* webpackChunkName: "info" */ '../views/Info')
const NotificationSettings = () => import(/* webpackChunkName: "notification-settings" */ '../views/NotificationSettings')
const Mission = () => import(/* webpackChunkName: "mission" */ '../views/Mission.vue')
const Dashboard = () => import(/* webpackChunkName: "mission" */ '../views/Dashboard.vue')
const Login = () => import(/* webpackChunkName: "auth" */ '../views/Login.vue')
const Register = () => import(/* webpackChunkName: "auth" */ '../views/Register.vue')
const NewDashboard = () => import(/* webpackChunkName: "new-dashboard" */ '../views/NewDashboard')
const Game = () => import(/* webpackChunkName: "new-dashboard" */ '../views/Game')
const WashingMachine = () => import(/* webpackChunkName: "new-dashboard" */ '../views/WashingMachine')
const HQ = () => import(/* webpackChunkName: "new-dashboard" */ '../views/HQ')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: VideoIntro
  },
  {
    path: '/intro-avatar',
    name: 'AvatarGameIntroScreen',
    component: AvatarGameIntroScreen
  },
  {
    path: '/intro-avatar-start',
    name: 'AvatarGameIntroSplash',
    component: AvatarGameIntroSplash
  },
  {
    path: '/welcome',
    name: 'AvatarGameWelcome',
    component: AvatarGameWelcome
  },
  {
    path: '/dashboard',
    name: 'NewDashboard',
    component: NewDashboard
  },
  {
    path: '/hq',
    name: 'HQ',
    component: HQ
  },
  {
    path: '/dash',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/avatar',
    name: 'Avatar',
    component: Avatar
  },
  {
    path: '/avatar-game',
    name: 'AvatarGame',
    component: AvatarGame
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/mission/:mission',
    name: 'Mission',
    component: Mission
  },
  {
    path: '/missions',
    name: 'Missions',
    component: Missions
  },
  {
    path: '/clarinha',
    name: 'Clarinha',
    component: Clarinha
  },
  {
    path: '/mission-topics/:mission',
    name: 'MissionTopics',
    component: MissionTopics
  },
  {
    path: '/mission/:mission/:topic',
    name: 'Topic',
    component: Topic
  },
  {
    path: '/mission/:mission/:topic/challenge',
    name: 'Challenge',
    component: Challenge
  },
  {
    path: '/mission/:mission/:topic/quiz',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/reward',
    name: 'Reward',
    component: Reward
  },
  {
    path: '/mission/:mission/:topic/find_words',
    name: 'Word',
    component: Word
  },
  {
    path: '/mission/:mission/:topic/phrase',
    name: 'Phrase',
    component: Phrase
  },
  /*
  {
    path: '/congrats',
    name: 'Congrats',
    component: Congrats
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy
  },
   */
  {
    path: '/game/:mission',
    name: 'Game',
    component: Game
  },
  {
    path: '/game/:mission/:topic',
    name: 'Game',
    component: Game
  },
  {
    path: '/wash/:mission/:topic',
    name: 'WashingMachine',
    component: WashingMachine
  },
  {
    path: '/wash/:mission',
    name: 'WashingMachine',
    component: WashingMachine
  },
  {
    path: '/wash',
    name: 'WashingMachine',
    component: WashingMachine
  },
  {
    path: '/info/:pageSlug',
    name: 'Info',
    component: Info
  },
  {
    path: '/:pageSlug',
    name: 'Info',
    component: Info,
    meta: {
      btnText: 'Sign Up'
    }
  },
  {
    path: '/notification-settings',
    name: 'NotificationSettings',
    component: NotificationSettings
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  adobeAnalytics(to.fullPath, document.title)
  next()
})

export default router
